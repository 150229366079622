#root,
.app {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
  max-width: 100vw; 
  overflow: hidden;

  .paper {
    display: flex;

    &.plain {
      flex: 1;
      align-items: flex-end;    
      height: 100vh;

      ul {
        width: 100%;

        li {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin: 1em;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-align: end;

          .icon {
            margin: 0 0 0 0.2em;
          }
        }
      }
    }
  
    &.colored {
      flex-direction: column;
      align-items: flex-start;
      flex: 3;
      height: 100vh;
      padding: 3em;
    }
  }
}

@media only screen and (max-width: 1000px) {
  #root,
  .app {
    flex-direction: column-reverse;

    .paper {
      &.plain {
        width: 100%;
      }

      &.colored {
        justify-content: flex-start;
        width: 100%;
      }
    }
  }
}

@media only screen and (max-height: 600px) {
  #root,
  .app {
    .paper {
      &.plain {
        li {
          margin: 0.2em 1em !important;
        }
      }
    }
  }
}
