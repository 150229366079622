@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@use "material-components-web/material-components-web";

html {
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
  min-width: 100vw;
  align-items: center;
  justify-content: center;

  body {
    font-family: 'Roboto', sans-serif;
    display: flex;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 100vw;
    min-width: 100vw;
    align-items: center;
    justify-content: center;
    margin: 0;
    background-color: #fafafa;
  }
}
